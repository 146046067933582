if (document.getElementById('dynamic__footer')) {
    const showDynamicFooter = () => { 
        const footer = document.getElementById('dynamic__footer');
        const footerPositionY = footer.offsetTop;
        const scrollTop = window.pageYOffset;
        const pageFooter = document.querySelector('footer')
        // console.log(scrollTop);
        if (scrollTop > 2200) {
            footer.classList.add('active')
            //footer.style.bottom = pageFooter.clientHeight + 'px'
        } else { 
            footer.classList.remove('active')
        }
    } 
    window.addEventListener('scroll', showDynamicFooter)
}
