const toggleDropdown = (el) => { 
    const dropdown = document.getElementById(el)
    const elements = dropdown.querySelectorAll('.dropdown__group label')
    dropdown.addEventListener('click', e => dropdown.classList.toggle('active'))
    elements.forEach(el => el.addEventListener('click', e => {
        elements.forEach(l => l.parentNode.classList.remove('active'))
        el.parentNode.classList.add('active')
        dropdown.classList.remove('active')
    }))
}

document.getElementById('dropdown_header') && toggleDropdown('dropdown_header')
document.getElementById('dropdown_main') && toggleDropdown('dropdown_main')