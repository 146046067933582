if (document.querySelectorAll('[data-percent]')) {

    const bars = document.querySelectorAll('[data-percent]')

    bars.forEach(bar => {
        const barPercentage = bar.dataset.percent
        bar.style.width = barPercentage * 100 / 61 - 10 + '%'
        bar.querySelector('span').innerHTML = barPercentage + '%'
    })
    
    // window.onscroll = () => { 
    //     const wScroll = window.pageYOffset
    //     const barOffset = bars[0].offsetTop
    //     const wHeight = window.innerHeight
    //     if (barOffset - wHeight / 2 < wScroll) {
    //         bars.forEach(bar => {
    //             const barPercentage = bar.dataset.percent
    //             bar.style.width = barPercentage * 100 / 61 - 10 + '%'
    //             bar.querySelector('span').innerHTML = barPercentage + '%'
    //         })
    //     } else { 
    //         bars.forEach(bar => {
    //             const barPercentage = bar.dataset.percent
    //             bar.style.width = '0%'
    //             bar.querySelector('span').innerHTML = '0%'
    //         })

    //     }

    // }
}
 
