/* =============================
* E-Mail Validierung
============================= */
const checkEmail = (el, error) => {
    const email = document.getElementById(el).value;
    const err = document.getElementById(error);
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
    reg.test(email) == false
    ) {
    err.classList.add("alert");
    err.innerHTML = "Bitte gültige E-Mail Adresse eingeben";
    return false
    } else {
    err.classList.remove("alert");
    err.innerHTML = "";
    return true
    }
};

const checkEmailInput = (el) => {
    const email = document.getElementById(el).value;
    const input = document.getElementById(el)
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(email)) {
        input.classList.add('success')
    } else { 
        input.classList.remove('success')
    }
};


//============================================
// Checkbox
//============================================

const isCheckboxChecked = (el, error, msg) => { 
    const checkbox = document.getElementById(el)
    const err = document.getElementById(error);
    if (checkbox.checked) {
      err.classList.remove("alert");
      err.innerHTML = "";
      return true
    } else { 
      err.classList.add("alert");
      err.innerHTML = msg;
      return false
    }
}
const isCheckboxCheckedBool = (el) => { 
    const checkbox = document.getElementById(el)
    if (checkbox.checked) {
      return true
    } else { 
      return false
    }
}

//============================================
// String Length
//============================================
const checkStringLength = (el, err, val, msg) => {
    const input = document.getElementById(el).value;
    const error = document.getElementById(err);
    
    if (/\d/.test(input) === false) {
    if (input.length < val) {
        error.classList.add("alert");
        error.innerHTML = msg;
        return false
    } else {
        error.classList.remove("alert");
        error.innerHTML = "";
        return true
    }

    } else { 
    error.classList.add("alert");
    error.innerHTML = msg;

    }    
};

const checkCharLength = (el, err, val, msg) => {
    const input = document.getElementById(el).value;
    const error = document.getElementById(err);
    if (input.length < val) {
    error.classList.add("alert");
    error.innerHTML = msg;
    } else {
    error.classList.remove("alert");
    error.innerHTML = "";
    nextQuestion();
    }
};

const checkStringLengthInput = (el, val) => {
    const string = document.getElementById(el).value;
    const input = document.getElementById(el)
    if (/\d/.test(string) === false && string.length >= val) {
      input.parentNode.classList.add('success')
    } else { 
      input.parentNode.classList.remove('success')
    }
};



//============================================
// VALIDIERUNG Anmeldung
//============================================

if (document.getElementById('anmeldung')) {
    const formAnmeldung = document.getElementById('anmeldung')
    const email = document.getElementById('email_anmeldung')
    const honeypot = document.getElementById('hp_anmeldung')

    // E-Mail Validierung
    email.addEventListener('keyup', () => checkEmailInput('email_anmeldung'))
    email.addEventListener('blur', () => checkEmailInput('email_anmeldung'))

    formAnmeldung.addEventListener('submit', e => { 
        e.preventDefault()       
        
        isCheckboxChecked('consent', 'validateCheckboxAnmeldung', 'Bitte akzeptieren Sie die Datenschutzbestimmungen')

        document.getElementById('checksession') &&
            isCheckboxChecked('consent', 'validateCheckboxAnmeldung', 'Bitte akzeptieren Sie die Datenschutzbestimmungen') && isCheckboxChecked('checksession', 'validateCheckboxAnmeldung', 'Bitte stimmen Sie einer erneuten Registrierung zu')
        
        if (honeypot.value.length > 0) { return }

        if (document.getElementById('checksession')) {
            if (
                checkEmail('email_anmeldung', 'validateEmailAnmeldung') &&
                isCheckboxChecked('consent', 'validateCheckboxAnmeldung', 'Bitte akzeptieren Sie die Datenschutzbestimmungen') &&
                isCheckboxCheckedBool('consent') &&
                isCheckboxChecked('checksession', 'validateCheckboxAnmeldung', 'Bitte stimmen Sie einer erneuten Registrierung zu')
            ) {
                formAnmeldung.submit()
            } else {
                
            }
        } else { 
            if (
                checkEmail('email_anmeldung', 'validateEmailAnmeldung') &&
                isCheckboxChecked('consent', 'validateCheckboxAnmeldung', 'Bitte akzeptieren Sie die Datenschutzbestimmungen') &&
                isCheckboxCheckedBool('consent')
            ) {
                formAnmeldung.submit()
            } else {
                
            }
        }
    })
}


//============================================
// VALIDIERUNG Gewinnspiel
//============================================

if (document.getElementById('gewinnspielteilnahme')) {
    const formGewinnspiel = document.getElementById('gewinnspielteilnahme')
    const emailGewinnspiel = document.getElementById('email_gewinnspiel')
    const honeypotGewinnspiel = document.getElementById('url_gewinnspiel')

    // E-Mail Validierung
    emailGewinnspiel.addEventListener('keyup', () => checkEmailInput('email_gewinnspiel'))
    emailGewinnspiel.addEventListener('blur', () => checkEmailInput('email_gewinnspiel'))

    formGewinnspiel.addEventListener('submit', e => { 
        e.preventDefault()       
        
        isCheckboxChecked('tnbConsent', 'validateCheckboxGewinnspiel', 'Bitte akzeptieren Sie die Datenschutzbestimmungen')
        isCheckboxChecked('teilnahmeConsent', 'validateConsentGewinnspiel', 'Bitte stimmen Sie der Teilnahme am Gewinnspiel zu') 
        
        if (honeypotGewinnspiel.value.length > 0) { return }

            if (
                checkEmail('email_gewinnspiel', 'validateEmailGewinnspiel') &&
                isCheckboxCheckedBool('tnbConsent') &&
                isCheckboxCheckedBool('teilnahmeConsent')
            ) {
                formGewinnspiel.submit()
            } else {
                console.log('RERERERERERR');
            }
        
    })
}
  


