const openModal = () => { 
    const modal = document.getElementById('modal')
    modal.classList.add('active')
}

const closeModal = () => { 
    const modal = document.getElementById('modal')
    modal.classList.remove('active')
}

document.addEventListener('keyup', e => { 
    e.key === 'Escape' && closeModal()
})

document.getElementById('modal') && document.getElementById('modal').addEventListener('click', e => e.target.id === 'modal' && closeModal()) 