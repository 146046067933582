const focusNextInputField = (el) => {
    const inputs = document.querySelectorAll(el)
    for (let i = 0; i < inputs.length; i++) { 
        inputs[i].addEventListener('keyup', e => { 
            if (inputs[i + 1] && inputs[i + 1].getAttribute('tabindex') != -1) {
                inputs[i + 1].focus();
                inputs[i + 1].select();
            } else { 
                if ((i + 2) < inputs.length) {
                    inputs[i + 2].focus();
                    inputs[i + 2].select();
                } else { 
                    inputs[0].focus()
                    inputs[0].select()
                }
            }        
        })
    }
}
document.querySelector('.raetsel__feld') && focusNextInputField('.raetsel__feld')
document.querySelector('.raetsel__feld_antwort') && focusNextInputField('.raetsel__feld_antwort')